<!--
    系统设置组件
-->
<template>
    <el-dialog :visible.sync="isShow" width="70%" modal-append-to-body top="5vh">

        <el-form :model="formDatas" label-width="180px">

            <el-tabs v-model="tabsActive">

                <el-tab-pane label="基础设置" name="baseSet">

                    <el-form-item label="开启键盘:" class="form-item-warp">
                        <el-switch v-model="formDatas.popKeyboard" active-color="#13ce66">
                        </el-switch>
                    </el-form-item>


                    <el-form-item label="默认时间:" class="form-item-warp">
                        <el-radio-group v-model="formDatas.showTimeType">
                            <el-radio :label="timeTypeEnum.orderTime">
                                下单时间
                            </el-radio>
                            <el-radio :label="timeTypeEnum.dispTime">
                                配送时间
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>



                    <el-form-item label="智能称:" class="form-item-warp">
                        <el-radio-group v-model="formDatas.scaleSet.isUse">
                            <el-radio :label="false">未连接</el-radio>
                            <el-radio :label="true">已连接</el-radio>
                        </el-radio-group>
                    </el-form-item>


                    <el-form-item label="重量单位:" class="form-item-warp">
                        <el-radio-group v-model="formDatas.scaleSet.weightUnit">
                            <el-radio :label="weightUnitEnum.jin">斤</el-radio>
                            <el-radio :label="weightUnitEnum.kg">公斤</el-radio>
                        </el-radio-group>
                    </el-form-item>


                </el-tab-pane>

                <el-tab-pane label="标签打印" name="printSet">

                    <el-form-item label="打印服务:" class="form-item-warp">
                        <el-select v-model="formDatas.printService" size="small" placeholder="请选择"
                            @change="printServChangeFn">
                            <el-option v-for="(item, index) in printServList" :key="index" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>


                    <el-form-item label="选择打印机:" class="form-item-warp">
                        <el-select v-model="formDatas.printId" size="small" placeholder="请选择">
                            <el-option v-for="(item, index) in printListData" :key="index" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>



                    <el-form-item label="打印方式:" class="form-item-warp">
                        <el-radio-group v-model="formDatas.printType">
                            <el-radio :label="printTypeEnum.handPrint">
                                手动打印
                            </el-radio>
                            <el-radio :label="printTypeEnum.autoPrint">
                                自动打印
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>


                </el-tab-pane>

                <!--
                <el-tab-pane label="出库单打印" name="chukuSet">
                    出库单打印相关设置
                </el-tab-pane>
                -->
            </el-tabs>



        </el-form>


        <div slot="footer">
            <el-button @click="isShow = false">取消</el-button>
            <el-button type="primary" @click="confirmBtnClickFn">确定</el-button>
        </div>


    </el-dialog>
</template>


<script>
import { getLodop } from '@/tools/libs/LodopFuncs.js'

import * as constants from '@/tools/constants.js'

import * as tools from '@/tools/tools.js'

import * as utils from '@/tools/utils.js'


import * as setCompApis from './set-comp-apis.js'





export default {
    data() {
        return {
            // 选项卡标识
            tabsActive: 'baseSet',

            // 模态框显示隐藏状态
            isShow: false,

            timeTypeEnum: constants.timeTypeEnum,

            weightUnitEnum: constants.weightUnitEnum,

            printTypeEnum: constants.printTypeEnum,


            printServList: [{
                id: constants.printServiceEnum.cloud,
                name: '智能云打印'
            }, {
                id: constants.printServiceEnum.lodop,
                name: 'C-Lodop'
            }],


            // 打印机列表数据
            printListData: [],

            // 表单数据
            formDatas: tools.cloneFn(constants.systemSetField)
        }
    },
    methods: {
        // 弹出模态框; 并回显表单数据
        showFn() {
            this.isShow = true;

            const cloneData = tools.cloneFn(this.$store.state.systemSet);

            console.log('system-set.vue showFn cloneData', tools.cloneFn(cloneData));

            this.formDatas = cloneData;


            // 再次打开设置模态框时显示第一个选项卡内容
            if (this.tabsActive != 'baseSet') {
                this.tabsActive = 'baseSet';
            }




            if (cloneData.printService) {
                this.getPrintListFn(cloneData.printService);
            }
        },
        // 确认提交按钮点击;
        confirmBtnClickFn() {
            console.log('confirmBtnClickFn');

            const formDatas = tools.cloneFn(this.formDatas);

            console.log('formDatas==>', formDatas);


            this.$store.commit('setStateDataFn', {
                systemSet: formDatas
            });

            localStorage.setItem('systemSetStorageKey', JSON.stringify(formDatas));

            this.isShow = false;

            this.$emit('commitSystemSetSuccess');

            this.$message.success('成功');

        },
        // 根据 打印机服务类型 获取对应的打印机列表数据
        async getPrintListFn(id) {
            console.log('printServChangeFn id', id);
            const that = this;
            const handleFnMap = {
                async [constants.printServiceEnum.cloud]() {

                    const result = await setCompApis.getPrintCloudListApi();

                    console.log('await setCompApis.getPrintCloudListApi result', result);

                    if (utils.checkSuccessfulFn(result)) {
                        that.printListData = result.list;
                    } else {
                        that.$message.error(`获取打印机列表失败`);
                    }
                },

                [constants.printServiceEnum.lodop]() {

                    const LODOP = getLodop();

                    console.log('LODOP', LODOP);

                    const num = LODOP.GET_PRINTER_COUNT() // 获取打印机个数

                    const list = [];
                    for (let i = 0; i < num; i++) {
                        list.push({
                            name: LODOP.GET_PRINTER_NAME(i),
                            id: i,
                        })
                    }
                    console.log('LODOP list', list);
                    that.printListData = list;
                },
            }
            await handleFnMap[id]();
        },

        // 选择打印服务时执行; 根据选择的服务类型获取对应的打印机列表数据
        async printServChangeFn(id) {
            console.log('printServChangeFn id', id);
            await this.getPrintListFn(id);
            this.formDatas.printId = '';
        },

    },
}
</script>
<style scoped lang="scss">
.form-item-warp {
    margin-bottom: 10px;
}
</style>
