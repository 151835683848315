/**
 * @file 所有挂载到 Vue.prototype上的公共函数
 */

import axios from 'axios'


import * as constants from '@/tools/constants.js'

import * as tools from '@/tools/tools.js'




export default {

	nextTickFn(that) {
		return new Promise(resFn => {
			that.$nextTick(() => {
				resFn();
			})
		})
	},

	setDatasFn(that, data) {
		Object.keys(data).forEach(key => {
			that[key] = data[key];
		});
	},


	// 停止3秒钟
	sleepFn(time = 3) {
		console.log(`sleepFn 阻止程序${time}秒`);
		return new Promise((resFn) => {
			setTimeout(() => {
				resFn(`sleepFn 阻塞完毕${time}秒`);
			}, time * 1000);
		});
	},


	// 封装网络请求
	['$fetch'](options) {
		const that = this;

		let {
			method, // 请求方式 {String}
			temUrl, // 临时的请求地址 一般用来调试 一般用不到
			url, // 请求地址 {String}
			data, // 请求数据 {Object}
			headers, // 请求头 {Object}

			resultDataType, // 返回数据格式默认 Object,

			handleErrFn
		} = options;

		method = method ? method : 'get';
		method = `${method}`.toUpperCase();

		resultDataType = resultDataType ? resultDataType : 'Object';


		handleErrFn = handleErrFn ? handleErrFn : ({
			msg
		}) => {
			// console.log('handleErrFn run', msg);
			that.$message.error(`${msg}`);
		}

		// 处理可能调试使用的 临时网络地址
		if (temUrl) {
			// 有临时的请求地址 就 用 临时的请求地址
			url = temUrl;
		} else {
			// 否则用默认的 host + url
			const host = that.getHostFn();

			url = `${host}${url}`;
		}



		const headerObject = {};
		if (that.checkLoginFn()) {
			headerObject.token = that.getClientTokenFn();
		}
		return new Promise(async resFn => {
			try {

				const result = await axios({
					timeout: 1000 * 60, // 请求超过60s 自动断开
					method,
					url,
					headers: {
						...headerObject,
						...headers
					},
					data: {
						...data,
						// token: that.getClientTokenFn()
					},
				});
				console.log('$fetch await axios result', result);

				const code = tools.objectGet(result, 'data.code');
				const msg = tools.objectGet(result, 'data.msg') || result.status;


				if (code == 200) {
					const resultObject = {
						code,
						msg,
						networkStatus: constants.networkEnum.networkSuccess,
						time: tools.objectGet(result, 'data.time'),
						data: tools.objectGet(result, 'data.data'),
					};
					if (resultDataType == 'Object') {
						resultObject.data = tools.objectGetObject(result, 'data.data');
					} else if (resultDataType == 'Array') {
						resultObject.data = tools.objectGetArray(result, 'data.data');
					}
					resFn(resultObject);
				} else {
					const resultObject = {
						networkStatus: constants.networkEnum.networkError,
						msg,
						result
					};
					handleErrFn(resultObject)
					resFn(resultObject);
				}
			} catch (e) {
				console.log('$fetch await axios catch e', e);
				const resultObject = {
					networkStatus: constants.networkEnum.networkError,
					msg: e.message,
					result: e
				};
				handleErrFn(resultObject);
				resFn(resultObject);
			}
		});
	},

	// 判断网络请求 是否成功; 成功返回true, 失败返回false
	checkNetworkSuccessFn(result) {
		if (tools.objectGet(result, 'networkStatus') === constants.networkEnum.networkSuccess) {
			return true;
		}
		return false;
	},

	// 判断数据是否正常; 成功返回true, 失败返回false
	checkResultDatasFn(result) {
		if (tools.objectGet(result, 'networkStatus') != constants.networkEnum.networkError) {
			return true;
		}
		return false;
	},


	// 从客户端本地获取token并返回
	getClientTokenFn() {
		const token = this.$store.state.token; // vuex全局状态管理里取
		return token ? token : localStorage.getItem('token'); // 全局状态里没有再返回localStorage
	},
	// 判断用户是否登录
	checkLoginFn() {
		if (this.getClientTokenFn()) {
			return true;
		}
		return false;
	},


	// 获取当前域名
	getHostFn() {
		const host = this.$store.state.host;
		return host ? host : tools.assertString(localStorage.getItem('host'));
	},





	// 返回上一页
	toBackFn() {
		// 正常返回上一页 如果没有上一页 就回首页
		if (getCurrentPages().length >= 2) {
			uni.navigateBack({});
		} else {
			this.navigateToFn({
				isTab: true,
				url: "/pages/index/index",
			});
		}
	},


	logoutFn() {
		localStorage.setItem('token', '');
		this.$store.commit('setStateDataFn', {
			token: ''
		});
		this.$router.replace('/login');
	},


	// 返回保留两位小数的字符串类型数字
	transDoubleNumStr(number, n = 2) {
		return tools.toDoubleNumFn(number, n);
	},




};
