

/**
 * @file 系统全局常量
 */




// 接口请求网络状态
export const networkEnum = (() => {

	const networkSuccess = 'networkSuccess', // 请求成功
		networkError = 'networkError'; // 请求失败
	return {
		[networkSuccess]: networkSuccess,
		[networkError]: networkError
	}
})();







// 时间类型
export const timeTypeEnum = (() => {

	const orderTime = 'orderTime', // 下单时间
		dispTime = 'dispTime'; // 配送时间
	return {
		[orderTime]: orderTime,
		[dispTime]: dispTime
	}
})();



// 称重单位
export const weightUnitEnum = (() => {

	const jin = 'jin', // 斤
		kg = 'kg'; // 公斤
	return {
		[jin]: jin,
		[kg]: kg
	}
})();


// 打印方式
export const printTypeEnum = (() => {

	const handPrint = 'handPrint', // 手动打印
		autoPrint = 'autoPrint'; // 自动打印

	return {
		[handPrint]: handPrint,
		[autoPrint]: autoPrint
	}
})();





// 打印服务
export const printServiceEnum = (() => {

	const lodop = 'lodop', // C-Lodop
		cloud = 'cloud'; // 智能云打印

	return {
		[lodop]: lodop,
		[cloud]: cloud
	}
})();



// 系统设置表单的数据结构
export const systemSetField = {

	// 是否弹出键盘
	popKeyboard: false,

	// 默认时间类型
	showTimeType: '',

	// 连接智能称
	scaleSet: {
		// 是否连接
		isUse: false,
		// 称重单位
		weightUnit: ''
	},

	// 打印服务
	printService: '',

	// 选中的打印机唯一标识
	printId: '',

	// 打印方式
	printType: ''
}


// 系统设置表单的默认数据
export const systemSetDefData = {
	// 默认弹出键盘
	popKeyboard: true,

	// 默认显示下单时间
	showTimeType: timeTypeEnum.orderTime,

	// 连接智能称
	scaleSet: {
		// 默认不连接智能称
		isUse: false,
		// 默认单位是公斤
		weightUnit: weightUnitEnum.kg
	},

	// 默认服务是 智能云打印控件
	printService: printServiceEnum.cloud,

	// 打印机 唯一标识默认空
	printId: '',

	// 打印方式 默认手动
	printType: printTypeEnum.handPrint
}



// 显示商品列数
export const goodsColNumberEnum = (() => {

	const fourCol = 'fourCol', // 四列
		twoCol = 'twoCol'; // 两列

	return {
		[fourCol]: fourCol,
		[twoCol]: twoCol
	}
})();

// 显示商品样式
export const goodsStyleEnum = (() => {

	const showImage = 'showImage', // 显示图片
		hideImage = 'hideImage'; // 隐藏图片

	return {
		[showImage]: showImage,
		[hideImage]: hideImage
	}
})();



// 分拣详情设置默认数据
export const sortingDetailSetDefData = {
	// 显示商品列数
	goodsColNum: goodsColNumberEnum.fourCol,
	// 显示商品样式
	showGoodsStyle: goodsStyleEnum.showImage,
	// 显示仓库位置
	storehouseLocation: true,

	// 显示称重框
	isShowWeighingFrame: false,

	// 称重框列表
	weighingFrameList: [],

	// 已分拣排列在底部
	sortedToBottom: false,
	// 显示打印小票按钮
	xiaopiaoPrintBtn: true,
}



// 分拣状态
export const sortingStateEnum = (() => {
	const unsorted = 'unsorted', // 未分拣
		sorted = 'sorted', // 已分拣
		quehuo = 'quehuo', // 缺货
		bufenquehuo = 'bufenquehuo'; // 部分缺货
	return {
		[unsorted]: unsorted,
		[sorted]: sorted,
		[quehuo]: quehuo,
		[bufenquehuo]: bufenquehuo
	}
})();


// 分拣状态映射
export const sortingStateMap = (() => {
	/*
		ew_fj_status: 分拣状态
			0：未分拣
			1：已分拣
			2：缺货
			3：部分缺货
	*/
	return {
		[0]: sortingStateEnum.unsorted,
		[1]: sortingStateEnum.sorted,
		[2]: sortingStateEnum.quehuo,
		[3]: sortingStateEnum.bufenquehuo
	}
})();





// 筛选条件-标品状态
export const biaopinStateEnum = (() => {

	const bpall = 'bpall', // 全部
		biaopin = 'biaopin', // 标品
		nobiaopin = 'nobiaopin'; // 非标品
	return {
		[bpall]: bpall,
		[biaopin]: biaopin,
		[nobiaopin]: nobiaopin
	}
})();



// 筛选条件-分拣状态
export const fenjianStateEnum = (() => {

	const fjall = 'fjall', // 全部
		unsortinged = 'unsortinged', // 待分拣
		sorting = 'sorting', // 分拣中
		sortinged = 'sortinged'; // 已分拣

	return {
		[fjall]: fjall,
		[unsortinged]: unsortinged,
		[sorting]: sorting,
		[sortinged]: sortinged
	}
})();




// 筛选条件-缺货状态
export const outStockStateEnum = (() => {

	const qhall = 'qhall', // 全部
		allOutStock = 'allOutStock', // 全部缺货
		partialOutStock = 'partialOutStock'; // 部分缺货

	return {
		[qhall]: qhall,
		[allOutStock]: allOutStock,
		[partialOutStock]: partialOutStock,
	}
})();



// 缺货状态
export const outOfStockStateEnum = (() => {
	const allOutOfStock = 'allOutOfStock', // 全部缺货
		partialOutOfStock = 'partialOutOfStock'; // 部分缺货
	return {
		[allOutOfStock]: allOutOfStock,
		[partialOutOfStock]: partialOutOfStock
	}
})();