import Vue from 'vue'
import VueRouter from 'vue-router'

import home from '@/views/home.vue'
import login from '@/views/login/login.vue'
import config from '@/service/serverConfig'

Vue.use(VueRouter)

const routes = [
	{
		path: '/',
		name: 'home',
		meta: {
			title: '首页'
		},
		component: home
	},
	{
		path: '/login',
		name: 'login',
		component: login
	},



	// 商品快捷打印页面
	{
		path: '/quickSorting',
		component: () => import('@/views/quick-sorting/quick-sorting.vue')
	},
	// 商品分拣页面
	{
		path: '/goodsSorting',
		component: () => import('@/views/goods-sorting/goods-sorting.vue')
	},

	// 用户分拣页面
	{
		path: '/userSorting',
		component: () => import('@/views/user-sorting/user-sorting.vue')
	},

	// 订单分拣页面
	{
		path: '/orderSorting',
		component: () => import('@/views/order-sorting/order-sorting.vue')
	},


	// 缺货分拣页面
	{
		path: '/outofstockSorting',
		component: () => import('@/views/outofstock-sorting/outofstock-sorting.vue')
	},
	
	
	// 预分拣页面
	{
		path: '/preSorting',
		component: () => import('@/views/pre-sorting/pre-sorting.vue')
	},


	{
		path: '/sorting',
		name: 'sorting',
		meta: {
			title: '分拣'
		},
		component: () => import('../views/sorting/index.vue')
	},
	
	
	{
		path: '/readySorting',
		name: 'readySorting',
		meta: {
			title: '预分拣'
		},
		component: () => import('../views/readySorting/index.vue')
	},
	{
		path: '/readySortingDetail',
		name: 'readySortingDetail',
		meta: {
			title: '预分拣详情'
		},
		component: () => import('../views/readySorting/readySortingDetail.vue')
	},
	{
		path: '/scanSorting',
		name: 'scanSorting',
		meta: {
			title: '扫码分拣'
		},
		component: () => import('../views/scanSorting/index.vue')
	},
	{
		path: '/tes1',
		name: 'tes1',
		component: () => import('../views/test-page/tes1.vue')
	},
]

const router = new VueRouter({
	// mode: 'history',
	mode: 'hash',
	base: process.env.BASE_URL,
	routes
})
//路由拦截
router.beforeEach((to, from, next) => {
	const token = localStorage.getItem(config.tokenName)

	// console.log('router.beforeEach token', token);

	if (token || to.path === '/login') {
		// console.log('router.beforeEach next()');
		next()
	} else {
		// console.log('router.beforeEach next(/login)');
		next({
			path: '/login',
			query: { redirect: to.fullPath }
		})
	}
})

export default router
