/**
 * @file 登录模块相关接口
 */

// 登录接口
export async function loginApi(that, data) {
    const result = await that.$fetch({
        method: 'post',
        url: '/api/Ewacc/login',
        data: {
            ...data,
            device: '6s2z6/fZtHqzrUJSpBQv9w=='
        },
    });
    console.log('/api/Ewacc/login result', result);

    if (that.checkNetworkSuccessFn(result)) {
        return result.data.token;
    }
    return result;
}