/**
 * @file 通用工具函数
 */ 

import bigJs from 'big.js';


// 检测类型
export function checkTypeFn(item) {
	return Object.prototype.toString.call(item).slice(8, -1);
}


// 数据转数字
export function transNumFn(item) {
	if (item) {
		let type = checkTypeFn(item);
		if (type == 'Number' || type == 'BigInt') {
			return item;
		} else if (type == 'String') {
			return transNumFn(item - 0);
		} else {
			return 1;
		}
	} else {
		return 0;
	}
}

// 断言 必须是对象
export function assertObject(item) {
	if (checkTypeFn(item) == 'Object' && Object.keys(item).length > 0) {
		return item;
	}
	return {};
}
// 断言 必须是数组
export function assertArray(item) {
	if (checkTypeFn(item) == 'Array' && item.length > 0) {
		return item;
	}
	return [];
}
// 断言 必须是字符串
export function assertString(item) {
	if (checkTypeFn(item) == 'String' && item.trim().length > 0) {
		return item.trim();
	}
	return '';
}
// 断言 必须是数字
export function assertNumber(item) {
	if (checkTypeFn(item) == 'Number' && item > 0) {
		return item;
	}
	return 0;
}





export function bigPlus(a, b) { //a+b
	return Number(new bigJs(a).plus(b).toFixed(9))
}

export function bigMinus(a, b) { //a-b
	return Number(new bigJs(a).minus(b).toFixed(9))
}

export function bigTimes(a, b) { //a*b
	return Number(new bigJs(a).times(b).toFixed(9))
}

export function bigDiv(a, b) { //a/b
	return Number(new bigJs(a).div(b).toFixed(9))
}


/*
	@description 获取保留两位小数的字符串数字
	@return <String> 带小数点的字符串数字
*/
export function toDoubleNumFn(item, n = 2) {
	let pow = Math.pow(10, n),
		num = bigTimes(transNumFn(item), pow),
		flag = num < 0 ? '-' : '+',
		abs = Math.abs(num),
		floor = Math.floor(abs) / pow,
		result = `${flag}${floor}` - 0;
	return result.toFixed(n)
}

// 深度克隆一份对象
export function cloneFn(item) {
	return JSON.parse(JSON.stringify(item))
}

// 深层对象获取值
export function objectGet(obj, keyStr) {
	let current = assertObject(obj),
		keyArr = `${keyStr}`.split('.').filter(i => i);
	keyArr.forEach((i, k) => current = k == keyArr.length - 1 ? current[i] : assertObject(current[i]));
	return current;
}

// 深层对象获取对象
export const objectGetObject = (obj, keyStr) => assertObject(objectGet(obj, keyStr));

// 深层对象获取数组
export const objectGetArray = (obj, keyStr) => assertArray(objectGet(obj, keyStr));

// 深层对象获取数字
export const objectGetNum = (obj, keyStr) => transNumFn(objectGet(obj, keyStr));

// 深层对象获取字符串
export const objectGetString = (obj, keyStr) => assertString(objectGet(obj, keyStr));


// 深层对象获取保留两位小数的数字
export const objectGetDoubleNum = (obj, keyStr, n = 2) => transNumFn(toDoubleNumFn(objectGet(obj, keyStr), n));

