<template>
    <div class="input-keyboard">
        <el-input v-model="inputValue" :autofocus="autofocus" :class="inputClass" ref="inputClass" :suffix-icon="suffixIcon"
            :type="type" :show-password="showPassword" :rows="rows" :show-word-limit="showWordLimit" :disabled="disabled"
            :maxlength="maxlength" :clearable="clearable" :size="size" :placeholder="placeholder"
            @click.native="focusInput($event)" @input="inputFun" @keyup.enter.native="keyupEnter">
            <template #prepend>
                <slot name="prepend"></slot>
            </template>
        </el-input>
        <el-drawer :visible.sync="showDrawer" direction="btt" :modal="false" :show-close="false" class="el-drawer-warp"
            :withHeader="false" destroy-on-close append-to-body>
            <h3 class="label">{{ inputValue }}</h3>
            <SimpleKeyboard v-if="showDrawer" ref="keyboardClass" keyboardClass="keyboardClass" @onChange="onChange"
                @onKeyPress="onKeyPress" @clear="inputValue = ''" @close="showDrawer = false" @bksp="del"
                :input="inputValue" :maxLength="maxlength" />
        </el-drawer>
    </div>
</template>

<script>
import SimpleKeyboard from './simpleKeyboard.vue'

export default {
    name: 'keyboard-input',
    components: {
        SimpleKeyboard
    },
    props: {
        keyboardClass: String,
        autofocus: Boolean,
        field: String,
        value: {
            default: ''
        },
        inputClass: String,
        type: {
            type: String,
            default: 'text'
        },
        showPassword: {
            type: Boolean,
            default: false
        },
        rows: Number,
        showWordLimit: {
            default: true
        },
        disabled: {
            type: Boolean,
            default: false
        },
        maxlength: Number,
        clearable: {
            type: Boolean,
            default: true
        },
        size: {
            type: String,
            default: () => {
                return 'medium'
            }
        },
        placeholder: String,
        append: {
            type: Boolean,
            default: false
        },
        autocomplete: {
            default: ''
        },
        suffixIcon: {
            default: ''
        }
    },
    data() {
        return {
            input: null,
            inputEle: null,
            showDrawer: false
        }
    },
    computed: {
        inputValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('inputChange', value, this.field)
                this.$emit('inputChangeFn', value, this.field)
            }
        }
    },
    methods: {
        inputChange() {
            this.$emit('inputChange')
            this.$emit('inputChangeFn')
        },
        inputFun() {
            this.$emit('input', this.inputValue)
        },
        keyupEnter() {
            this.$emit('keyupEnter', this.inputValue)
        },
        focusInput(e) {

            // 系统设置为不开启时不执行;
            if (!this.$store.state.systemSet.popKeyboard) {
                return false;
            }

            this.showDrawer = true;

            this.$nextTick(() => {

                this.$refs.keyboardClass.show();
            })
            this.$emit('focus');
        },
        onChange(input) {
            this.inputValue = input
            // 解决当输入框为密码输入框时，切换显示/隐藏密码，光标在开头问题，注意：element-ui版本号需为2.15.2及以上
            this.$refs.inputClass.focus()
        },
        onKeyPress(button) {
            // console.log('onKeyPress', button);
        },
        del() {
            if (this.inputValue.length > 0) {
                this.inputValue = this.inputValue.slice(0, -1)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.input-keyboard {
    ::v-deep(.hg-theme-default) {
        position: absolute;
        left: 50%;
        bottom: 0;
        transform: translate(-50%);
        z-index: 2000;

        .hg-candidate-box {
            position: static;
            transform: translateY(0);
        }

        // 键盘
        .hg-rows {}
    }

    &.citc-search-input {
        .el-input {
            width: 100% !important;
        }
    }

    ::v-deep(.el-input__inner[type='text'] + span .el-icon-view) {
        color: red;
    }
}

::v-deep(.el-input__inner) {
    //height: auto !important;
}

::v-deep(.el-input__icon) {
    line-height: inherit !important;
}

.label {
    height: 55px;
    font-size: 20px;
    line-height: 55px;
    text-align: center;
}

::v-deep(.el-drawer) {
    height: 285px !important;
}
</style>
