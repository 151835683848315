<template>
	<div id="app"><router-view /></div>
</template>

<script>
import * as tools from '@/tools/tools.js';
import * as utils from '@/tools/utils.js';





import { queryConfig } from '@/service/sorting';
import serverConfig from '@/service/serverConfig';

export default {
	created() {
		console.log('App.vue created run', {
			state: tools.cloneFn(this.$store.state)
		});

		// 同步 系统设置 的 localStorage、$store数据并初始化
		utils.syncSystemStorageStoreFn(this);

		// 同步 分拣详情设置 的 localStorage、$store数据并初始化
		utils.syncSortingDetailStorageStoreFn(this);
	},
	mounted() {
		// 开发测试时用于加载字体图标代码; 线上正式发布需把实际字体包下载到项目里
		/* 
            const fontLink = 'https://at.alicdn.com/t/c/font_4191965_ovizj5fzun.css'
            const link = document.createElement('link')
            link.setAttribute('rel', 'stylesheet')
            link.setAttribute('href', `${fontLink}?${Math.random()}`)
            document.head.appendChild(link);
        */

		// 监听全屏切换事件
		document.addEventListener('fullscreenchange', e => {
			let isFullscreen = false;
			if (document.fullscreenElement) {
				// 全屏状态
				console.log('进入全屏');
				isFullscreen = true;
			} else {
				// 非全屏状态
				console.log('退出全屏');
			}

			this.$store.commit('setStateDataFn', {
				isFullscreen
			});
		});
	},
	watch: {
		$route: {
			handler() {
				this.$store.commit('getSystemInfo');
			},
			immediate: true
		}
	}
};
</script>
<style lang="scss">
* {
	margin: 0;
	padding: 0;
	box-sizing: border-box;
	outline: none;
	list-style: none;
}

.flex-center {
	display: flex;
	justify-content: center;
	align-items: center;
}

.border-radius {
	border-radius: 10000000px;
}

.text-ellipsis {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.text-ellipsis-rows {
	text-overflow: ellipsis;
	/*设置溢出显示类型为...*/
	-webkit-line-clamp: 2;
	/*设置显示的行数*/
	overflow: hidden;
	/*隐藏多于行*/
	display: -webkit-box;
	/*将对象作为弹性伸缩盒子模型显示*/
	-webkit-box-orient: vertical;
	/*设置或检索伸缩盒对象的子元素的排列方式*/
}

@font-face {
	font-family: 'iconfont';
	src: url('./assets/iconfont.woff2?t=1690862466559') format('woff2');
}

.iconfont {
	font-family: 'iconfont' !important;
	font-size: 16px;
}

.icon-sanjiao-xia:before {
	content: '\e600';
}

.icon-tuichu:before {
	content: '\e6ef';
}

.icon-mima:before {
	content: '\e617';
}

.icon-wangzhi:before {
	content: '\e654';
}

.icon-user:before {
	content: '\e603';
}
</style>
